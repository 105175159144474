import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import * as authSelectors from '../../../store/auth/auth.selectors';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit {

  isLoggedInObservable = this.store.select(authSelectors.loggedUser);

  miusuario;

  constructor(private store: Store) {

  }

  ngOnInit() {
    this.isLoggedInObservable.subscribe((data)=>{
      console.log(data);
      this.miusuario=data;
      console.log(this.miusuario);
    })
  }
}
