import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as authActions from '../store/auth/auth.actions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
  constructor(private store: Store, private router: Router) { }

  ngOnInit() {
    const user = localStorage.getItem('logged-user-pto-venta') || null;
    const userCredentials = user ? JSON.parse(user) : null;
    if (userCredentials) {
      this.store.dispatch(authActions.loggedUserUpdated({ loggedUser: userCredentials }));
      this.router.navigate(['fact']);
    }
    else {
      this.router.navigate(['/']); 
    }
  }
}
