
<div class="sidebar-wrapper">
  <div class="logo">
    <a  class="simple-text logo-mini">
      <div class="logo-image-small">
        <img src="assets/img/fel.ico">
      </div>
    </a>
    <a  class="simple-text logo-normal">
      COMPUETC
    </a>
  </div>
    <ul class="nav">
        <li *ngFor="let menuItem of menuItems" routerLinkActive="active" class="{{menuItem.class}}">
            <a [routerLink]="[menuItem.path]">
                <i class="nc-icon {{menuItem.icon}}"></i>
                <p>{{menuItem.title}}</p>
            </a>
        </li>
        <li>
          <a class="empty-button" (click)="logout()">
              <i class="nc-icon nc-lock-circle-open"></i>
              <p>Cerrar Sesion</p>
          </a>
      </li>
    </ul>
</div>
