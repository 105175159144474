<div class="wrapper">
    <div class="sidebar" data-color="white" data-active-color="danger" *ngIf="isLoggedInObservable|async">
        <sidebar-cmp></sidebar-cmp>
    </div>
    <div class="main-panel" [class.w-100]="!(isLoggedInObservable|async)">
        <navbar-cmp *ngIf="isLoggedInObservable|async"></navbar-cmp>
        <div class="content">
            <router-outlet></router-outlet>
        </div>
        <footer-cmp></footer-cmp>
    </div>
</div>
