import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as authActions from '../../store/auth/auth.actions';


export interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}

export const ROUTES: RouteInfo[] = [
    { path: '/dashboard', title: 'Dashboard', icon: 'nc-chart-pie-36', class: '' },
   // { path: '/empresa', title: 'Empresa', icon: 'nc-shop', class: '' },
    { path: '/fact', title: 'Facturacion', icon: 'nc-paper', class: '' },
    { path: '/reimprimir', title: 'Reimprimir', icon: 'nc-box', class: '' },
  //  { path: '/clientes', title: 'Clientes', icon: 'nc-single-02', class: '' },
   // { path: '/series', title: 'Series', icon: 'nc-book-bookmark', class: '' },
  //  { path: '/NotasC', title: 'Notas', icon: 'nc-single-copy-04', class: '' },
   // { path: '/table', title: 'Productos', icon: 'nc-basket', class: '' },
    { path: '/reportes', title: 'Reportes', icon: 'nc-money-coins', class: '' },
   



];

@Component({
    moduleId: module.id,
    selector: 'sidebar-cmp',
    templateUrl: 'sidebar.component.html',
    styleUrls: ['sidebar.component.css']
})

export class SidebarComponent implements OnInit {
    public menuItems: any[];

    constructor(private store: Store, private router: Router) {

    }

    ngOnInit() {
        this.menuItems = ROUTES.filter(menuItem => menuItem);
    }

    logout() {
        this.store.dispatch(authActions.loggedUserUpdated({ loggedUser: null }));
        localStorage.removeItem('logged-user-pto-venta');
        this.router.navigate(['/']);
    }
}
