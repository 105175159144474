import { Action, ActionReducer, createReducer, on } from "@ngrx/store";
import { loggedUserUpdated } from "./auth.actions";
import { initialState, AuthState } from "./auth.state";

const loggedUserUpdatedReducer = (state: AuthState, action: any) => {
    return {
        ...state,
        loggedUser: action.loggedUser
    }
}


export const authReducer: ActionReducer<AuthState, Action> = createReducer(
    initialState,
    on(loggedUserUpdated, loggedUserUpdatedReducer),
)