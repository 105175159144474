<footer class="footer">
    <div class="container-fluid">
        <nav class="pull-left">
            <ul>

                
                <li>
                    <a href="https://www.facebook.com/CompuETCGuatemala">
                       Facebook
                    </a>
                </li>
               
            </ul>
        </nav>
        <div class="copyright pull-right">
            &copy; {{test | date: 'yyyy'}},   <a >COMPUETC</a>
        </div>
    </div>
</footer>
